import {apiSlice} from './apiSlice';
import {ORDERS_URL, PAYPAL_URL} from '../constants';

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: {...order}
            })
        }),
        getOrderDetails: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`
            }),
            keepUnusedDataFor: 5
        }),
        payOrder: builder.mutation({
            query: ({orderId, details}) => ({
                url: `${ORDERS_URL}/${orderId}/pay`,
                method: 'PUT',
                body: details,
            }),
        }),
        getPayPalClientId: builder.query({
            query: () => ({
                url: PAYPAL_URL,
            }),
            keepUnusedDataFor: 5
        }),
        getMyOrders: builder.query({
            query: ({pageNumber}) => ({
                url: `${ORDERS_URL}/mine`,
                params: {
                    pageNumber,
                },
            }),
            keepUnusedDataFor: 5
        }),
        getOrders: builder.query({
            query: ({pageNumber}) => ({
                url: ORDERS_URL,
                params: {
                    pageNumber,
                },
            }),
            keepUnusedDataFor: 5,
        }),
        deliverOrders: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/deliver`,
                method: 'PUT'
            })
        }),
        deleteOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const {
    useCreateOrderMutation, 
    useGetOrderDetailsQuery, 
    usePayOrderMutation, 
    useGetPayPalClientIdQuery,
    useGetMyOrdersQuery,
    useGetOrdersQuery,
    useDeliverOrdersMutation,
    useDeleteOrderMutation,
} = ordersApiSlice;