// export const BASE_URL = process.env.NODE_ENV === 'development' ? 
//     'localhost://5000' : '';

export const BASE_URL = ''
export const PRODUCTS_URL = '/api/products';
export const USERS_URL = '/api/users';
export const ARTSHOW_URL = '/api/artshow';
export const ORDERS_URL = '/api/orders';
export const PAYPAL_URL = '/api/config/paypal';
export const UPLOAD_URL = '/api/upload';
export const SHIPPO_URL = '/api/shippo'

