import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Typewriter from 'typewriter-effect';
import '../assets/styles/screens/tempShopScreen.css'

const TempShopScreen = () => {
  return (
    <Container className='coming-soon-container'>
                  <Row className='hr-container'>
                    <div className='top-hr'>
                      <div className='hr-phrase'>
                        C
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        M
                      </div>
                      <div className='hr-phrase'>
                        I
                      </div>
                      <div className='hr-phrase'>
                        N
                      </div>
                      <div className='hr-phrase'>
                        G
                      </div>
                      <div className='hr-phrase'>
                        &nbsp;
                      </div>
                      <div className='hr-phrase'>
                        S
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        N
                      </div>
                    </div>
                  <div className='mid-hr'>
                    <div className='hr-phrase'>
                      C
                    </div>
                    <div className='hr-phrase'>
                      O
                    </div>
                    <div className='hr-phrase'>
                      M
                    </div>
                    <div className='hr-phrase'>
                      I
                    </div>
                    <div className='hr-phrase'>
                      N
                    </div>
                    <div className='hr-phrase'>
                      G
                    </div>
                    <div className='hr-phrase'>
                      &nbsp;
                    </div>
                    <div className='hr-phrase'>
                      S
                    </div>
                    <div className='hr-phrase'>
                      O
                    </div>
                    <div className='hr-phrase'>
                      O
                    </div>
                    <div className='hr-phrase'>
                      N
                    </div>
                  </div>
                  <div className='btm-hr'>
                    <div className='hr-phrase'>
                        C
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        M
                      </div>
                      <div className='hr-phrase'>
                        I
                      </div>
                      <div className='hr-phrase'>
                        N
                      </div>
                      <div className='hr-phrase'>
                        G
                      </div>
                      <div className='hr-phrase'>
                        &nbsp;
                      </div>
                      <div className='hr-phrase'>
                        S
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        O
                      </div>
                      <div className='hr-phrase'>
                        N
                      </div>
                    </div>
                  </Row>  
                </Container>
  )
}

export default TempShopScreen